<template>
    <b-modal :id="uniqueId" :ref="uniqueId" @hidden="handleClose">
        <template slot="modal-header" slot-scope="{ close }">
            <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
            <h2 class="mx-auto my-auto">{{ $t('syndication.ajouter_porteurs_parts_moal_titre') }}</h2>
        </template>
        <template slot="default">
            <b-form>
                <div class="form-group">
                    <label>{{ $t('syndication.nombre_parts') }}</label>
                    <b-input v-model="carriers.parts" type="number"></b-input>
                </div>
                
                <div class="form-group">
                    <label>{{ $t('syndication.type_facturation_part') }}</label>
                    <e-select
                        v-model="carriers.invoice_type"
                        id="invoicetype_id"
                        track-by="invoicetype_id"
                        label="invoicetype_labeltrad"
                        :selectedLabel="$t('global.selected_label')"
                        :options="syndic_invoice_types"
                        :searchable="true"
                        :allow-empty="false"
                        :loading="loading"
                        :show-labels="true"
                    >
                        <template slot="option" slot-scope="{ option }">{{ $t(option.invoicetype_labeltrad) }}</template>
                        <template slot="singleLabel" slot-scope="{ option }">{{ $t(option.invoicetype_labeltrad) }}</template>
                    </e-select>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>{{ $t('syndication.saillies_perso') }}</label>
                            <b-input v-model="carriers.saillies_perso" readonly></b-input>
                        </div>
                    </div>

                    <div class="col">
                        <div class="form-group">
                            <label>{{ $t('syndication.saillies_pool') }}</label>
                            <b-input v-model="carriers.saillies_pool" type="number" :max="maxSaillieQuantity"></b-input>
                        </div>
                    </div>
                </div>
                <div class="alert alert-danger" v-if="bad_saillie_repartition">{{ erreur_trad }}</div>
                <div class="form-group">
                    <label>{{ $t('syndication.ajouter_porteurs') }}</label>
                    <e-select
                        v-model="carriers.tiers"
                        track-by="tiers_id"
                        label="tiers_rs"
                        :placeholder="$t('syndication.selectionner_tiers_porteurs')"
                        :selectedLabel="$t('global.selected_label')"
                        :options="tiers"
                        :searchable="true"
                        :allow-empty="false"
                        :loading="loading"
                        :show-labels="true"
                        :multiple="true"
                    />
                </div>
                <div v-if="carriers.tiers.length > 0" class="form-group">
                    <label>{{ $t('syndication.repartition_porteurs') }}</label>
                    <div class="mb-3" v-for="(tier, idx) in carriers.tiers" :key="'tier_percent_'+idx">
                        <b-input-group :prepend="tier.tiers_rs" append="%">
                            <b-form-input v-model="carriers.tiers[idx].percentage" type="number" step="0.01" min="0.01" max="100"></b-form-input>
                        </b-input-group>
                    </div>
                </div>
            </b-form>
        </template>

        <template slot="modal-footer" class="justify-content-center">
            <b-button variant="primary" @click="checkForm()" :disabled="!formComplete || processing">
                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                {{ $t('global.ajouter') }}
            </b-button>
        </template>
    </b-modal>
</template>


<script type="text/javascript">
    import Gynecologie from "@/mixins/Gynecologie.js"
    import Syndic from "@/mixins/Syndic.js"
    import Tiers from "@/mixins/Tiers.js"

	export default {
        name: "AddSyndicCarrierModal",
        mixins: [Gynecologie, Syndic, Tiers],
        props: {
            submitForm: { type: Function, default: () => false },
            syndic: { type: Object, default: null },
			syndic_invoice_types: { type: Array, default: () => [] }
        },
        data() {
            return {
                tiers: [],
                loading: true,
                processing: false,
                form_sent: false,
                carriers: {
                    tiers: [],
                    parts: 1,
                    saillies_perso: 1,
                    saillies_pool: 0,
                    invoice_type: null
                },
                bad_saillie_repartition: false,
                erreur_trad: ''
            }
        },
        computed: {
            uniqueId() {
                return 'modal-add-carrier-'+this._uid
            },
            formComplete() {
                return this.carriers.tiers.length > 0
                    && !this.hasInvalidRepartition
                    && !this.hasInvalidPercentage
            },
            hasInvalidRepartition() {
                return (parseInt(this.carriers.saillies_perso) + parseInt(this.carriers.saillies_pool)) != (parseInt(this.carriers.parts) * this.sailliePerPart)
            },
            hasInvalidPercentage() {
                let result = false
                let total = 0

                this.carriers.tiers.forEach(tier => {
                    if(!tier.percentage || tier.percentage < 0 || tier.percentage > 100) {
                        result = true
                        return
                    }

                    total += parseFloat(tier.percentage)
                })

                return result || total > 100
            },
            sailliePerPart() {
                if(!this.syndic || !this.syndic.syndic_saillies_par_part) return 1
                return this.syndic.syndic_saillies_par_part
            },
            maxSaillieQuantity() {
                if(!this.carriers.parts) return 1
                return parseInt(this.carriers.parts) * this.sailliePerPart
            }
        },
        watch: {
            'syndic': {
                handler(val) {
                    this.setPartsRatio()
                },
                deep: true
            },
            'carriers.parts' (val) {
                // Si input vide
                if(!val) return

                // Si on change la quantité de part, on recalcul la répartition, en gardant si possible les paramètres entrés par l'utilisateur
                if(this.carriers.saillies_pool > this.maxSaillieQuantity) {
                    this.carriers.saillies_pool = this.maxSaillieQuantity
                }

                this.carriers.saillies_perso = this.maxSaillieQuantity - parseInt(this.carriers.saillies_pool)
            },
            'carriers.saillies_pool' (val) {
                this.bad_saillie_repartition = false

                // A chaque changement du nombre de saillie du pool, on recalcul les restantes, en perso
                if(!val) return
                const remain = this.maxSaillieQuantity - parseInt(this.carriers.saillies_pool)
                
                if(remain < 0) {
                    this.erreur_trad = this.getTrad('syndication.mauvaise_repartition_saillies', [
                        this.carriers.parts,
                        this.sailliePerPart,
                        this.maxSaillieQuantity
                    ])
                    this.bad_saillie_repartition = true
                    return
                }
                this.carriers.saillies_perso = remain
            },
			'syndic_invoice_types' (val) {
				this.carriers.invoice_type = val[0]
			}
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                let tab_promises = []
				this.carriers.invoice_type = this.syndic_invoice_types.length > 0 ? this.syndic_invoice_types[0] : null
                
                tab_promises.push(new Promise((rsv, _) => {
                    this.loadTiers()
                        .then(res => {
                            res.forEach((tier, idx) => {
                                res[idx].percentage = 100
                            })
                            this.tiers = res
                        })
                        .finally(rsv())
                }))

                await Promise.all(tab_promises)
                this.setPartsRatio()
                this.loading = false
            },

            checkForm() {
                if(this.formComplete) {
                    this.processing = true

                    this.submitForm({
                        tiers: this.carriers.tiers.map(tier => { 
                            return { 'id': tier.tiers_id, 'percentage': tier.percentage }
                        }),
                        parts: this.carriers.parts,
                        saillies_perso: this.carriers.saillies_perso,
                        saillies_pool: this.carriers.saillies_pool,
                        invoice_type: this.carriers.invoice_type.invoicetype_id
                    })

                    this.form_sent = true
                }
            },

            resetForm() {
                this.carriers.tiers = []
                this.carriers.parts = 1
                this.setPartsRatio()
            },

            setPartsRatio() {
                if(!this.syndic) return
                
                // Par défaut, on set le nombre de parts perso au max
                this.carriers.saillies_perso = this.sailliePerPart
            },

            openModal() {
                this.$bvModal.show(this.uniqueId)
            },

            closeModal() {
                this.$bvModal.hide(this.uniqueId)
            },

            handleClose() {
                // Si le formulaire a bien été envoyé, à la fermeture de la modale, on reset le formulaire
                if(this.form_sent === true) {
                    this.resetForm()
                    this.form_sent = false
                    this.processing = false
                }
            }
        }
	}
</script>
